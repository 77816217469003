import React from 'react';
import Popup from 'reactjs-popup';
import './style.css';
import '../../App.css';
const renderCompleteIcon = (isIcon) => {
    if (isIcon)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(45, 45, 45)" xmlns="http://www.w3.org/2000/svg"><path d="M3 12L9 18L21 6" stroke="rgba(255,255,255,0.95)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        )
    else
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(45, 45, 45)" xmlns="http://www.w3.org/2000/svg"><path d="M3 12L9 18L21 6" stroke="rgba(255,255,255,0.2)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        )
}
export default () => {

    return (
        <Popup
            trigger={<h2 className="labelfeature">Upcoming Features</h2>}
            modal
            nested
        >
            {close => (
                <div className="modal">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="header"> Upcoming Features </div>
                    <div className="content">
                        <p className='featureList'>{renderCompleteIcon(true)} Just record and Tweet</p>
                        <p className='featureList'>{renderCompleteIcon(false)} Schedule a Tweet</p>
                        <p className='featureList'>{renderCompleteIcon(false)} More language support</p>
                        <p className='featureList'>{renderCompleteIcon(false)} AI commands for tagging and scheduling</p>
                        <p className='featureList'>{renderCompleteIcon(false)} Automatic thread if long recording</p>
                        <p className='featureList'>{renderCompleteIcon(false)} Upload recording and convert to tweet</p>
                        <p className='featureList'>{renderCompleteIcon(false)} Reply fast to curated people's tweet</p>
                        <p className='featureList'>{renderCompleteIcon(false)} <a style={{ color: 'white' }} href='mailto:hello@nimishpatel.me?subject=I would like to add this features'>Add your's suggestions.</a></p>
                    </div>
                    <div className="actions">
                        {/* <Popup
                        trigger={<button className="button"> Trigger </button>}
                        position="top center"
                        nested
                    >
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
                            magni omnis delectus nemo, maxime molestiae dolorem numquam
                            mollitia, voluptate ea, accusamus excepturi deleniti ratione
                            sapiente! Laudantium, aperiam doloribus. Odit, aut.
                        </span>
                    </Popup> */}
                        {/* <button
                            className="button"
                            onClick={() => {
                                console.log('modal closed ');
                                close();
                            }}
                        >
                            close modal
                        </button> */}
                    </div>
                </div>
            )}
        </Popup>
    )
};