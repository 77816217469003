import './App.css';
import { useEffect, useState } from 'react';
// import { Vocal } from '@untemps/vocal';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import axios from 'axios';
import queryString from 'query-string';
// import { Waitlist } from 'waitlistapi';
import { BsStopFill, BsFillMicFill } from "react-icons/bs";
import Feature from './components/popup/feature';
import trackEvent from './components/Analytics';

const apiPath = "https://twitterrecorderservice.herokuapp.com/api";
const defaultText = "Click to record";

function App() {

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [token, setToken] = useState("");
  const [recentTweet, setRecentTweeet] = useState("");
  const [logState, setLogState] = useState(defaultText);
  // const [isVocalEnd, setIsVocalEnd] = useState(false);

  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  // //Vocal
  // const options = {
  //   continuous: true,
  // }
  // const vocal = new Vocal(options);
  // //Events
  // vocal.addEventListener('speechstart', (event) => {
  //   setIsVocalEnd(false);
  //   setLogState("Listening...")
  // });
  // vocal.addEventListener('speechend', (event) => {

  // })
  // vocal.addEventListener('result', async (event, result) => {
  //   console.log("result", result);
  //   let transcript = result;
  //   if (isVocalEnd) {
  //     if (token != "" && transcript != "") {
  //       console.log("Tweeting");
  //       setLogState("Tweeting...");
  //       const response = await axios({
  //         url: `${apiPath}/twitter/tweet?token=${token}`,
  //         method: 'POST',
  //         data: { text: transcript },
  //       });
  //       console.log("response from Tweet", response);
  //       if (response?.data?.data?.id != undefined) {
  //         setRecentTweeet(response.data.data.text);
  //       }
  //       setLogState(defaultText);
  //       setIsVocalEnd(false);
  //     }
  //     else {
  //       setLogState("No data. Try again.")
  //     }
  //   }
  // });
  // vocal.addEventListener('error', (error) => {
  //   setLogState(error);
  // })

  const recordClick = async (e) => {
    e.preventDefault();
    console.log("started..");
    if (isLoggedIn) {
      // if (Vocal.isSupported) {
      //   setIsPlaying(!isPlaying);
      //   if (!isPlaying) {
      //     vocal.start();
      //   }
      //   else {
      //     setIsVocalEnd(true);
      //     vocal.stop();
      //   }
      // }
      // else {
      setIsPlaying(!isPlaying);
      if (!isPlaying) {
        setLogState("Listening...")
        SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
      }
      else {
        SpeechRecognition.stopListening();
        console.log("transcript", transcript);
        console.log("token", token);
        setLogState("Tweeting...")
        const response = await axios({
          url: `${apiPath}/grammer/correction?sentense=${transcript}`,
          method: 'GET',
        });
        let finalText = response.data.sentense;
        console.log("finalText", finalText);
        if (token != "" && finalText != "") {
          const response = await axios({
            url: `${apiPath}/twitter/tweet?token=${token}`,
            method: 'POST',
            data: { text: finalText },
          });
          console.log("response from Tweet", response);
          if (response?.data?.data?.id != undefined) {
            setRecentTweeet(response.data.data.text);
            trackEvent("tweet_success", { username: username });
          }
          resetTranscript();
          setLogState(defaultText);
        }
        else {
          setLogState("No data. Try again.")
          console.log("No data");
        }
      }
      //}
    }
  }
  const login = () => {
    (async () => {

      try {
        //OAuth Step 1
        const response = await axios({
          url: `${apiPath}/twitter/oauth/request_token`,
          method: 'POST'
        });
        console.log("response", response);
        const { oauth_token } = response.data;
        setToken(oauth_token);
        //Oauth Step 2
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      } catch (error) {
        console.error(error);
      }

    })();
  }

  const logout = () => {
    (async () => {
      try {
        await axios({
          url: `${apiPath}/twitter/logout`,
          method: 'POST'
        });
        setIsLoggedIn(false);
        localStorage.removeItem("oauth_token");
        localStorage.removeItem("oauth_verifier");
        window.location.href = '/';
      } catch (error) {
        console.error(error);
      }
    })();
  }

  useEffect(() => {
    (async () => {
      // if (!Vocal.isSupported && !browserSupportsSpeechRecognition) {
      if (!browserSupportsSpeechRecognition) {
        console.log("browserSupportsSpeechRecognition", browserSupportsSpeechRecognition);
        setLogState("Browser doesn't support speech recognition.")
        return;
      }
      let { oauth_token, oauth_verifier } = queryString.parse(window.location.search);
      if (!oauth_token || !oauth_verifier) {
        oauth_token = localStorage.getItem("oauth_token");
        oauth_verifier = localStorage.getItem("oauth_verifier");
        console.log("get it from local item");
      }
      if (oauth_token && oauth_verifier) {
        localStorage.setItem("oauth_token", oauth_token);
        localStorage.setItem("oauth_verifier", oauth_verifier);
        try {
          //Oauth Step 3
          await axios({
            url: `${apiPath}/twitter/oauth/access_token`,
            method: 'POST',
            data: { oauth_token, oauth_token, oauth_verifier },
          });
        } catch (error) {
          console.error(error);
        }
      }
      if (oauth_token) {
        try {
          //Authenticated Resource Access
          const { data } = await axios({
            url: `${apiPath}/twitter/users/profile_banner?token=${oauth_token}`,
            method: 'GET',
            withCredentials: true
          });
          const { name, username } = data.data;
          setIsLoggedIn(true);
          setName(name);
          setUserName(username);
          setToken(oauth_token);
          trackEvent("login_success", { usename: username });
          // setImageUrl(profile_image_url_https);
          // setStatus(status.text);
          // setUrl(entities.url.urls[0].expanded_url);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);

  return (
    <div className="container" >
      <main>
        <div className="center">

          <div style={{ float: 'left', marginLeft: 20, display: 'inline-block' }}>
            <p className='labelLogo'>tweetee</p>
          </div>
          <div style={{ float: 'right', marginRight: 20, marginTop: 10 }}>
            {isLoggedIn ?
              <div style={{ display: 'inline-block' }}><p className='labellogin'>{name}</p><p className='labellogout' onClick={logout}>Log out</p></div> :
              <p className='labellogin' onClick={login}>Login with Twitter</p>
            }

          </div>
          <div id="control" className={isPlaying ? "is--playing" : ""} onContextMenu={(e) => e.preventDefault()}>
            <div className="border" onClick={recordClick}>
              <div className="play">
                <div className='inicon'>
                  {isPlaying ?
                    <BsStopFill color='white' size={96} /> :
                    <BsFillMicFill color='white' size={96} />
                    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M256,0c-49.456,0-89.693,40.237-89.693,89.693v176.279c0,49.45,40.237,89.687,89.693,89.687c49.456,0,89.693-39.957,89.693-89.071V89.693C345.693,40.237,305.456,0,256,0z M308.315,266.583c0,28.503-23.47,51.698-52.315,51.698s-52.321-23.464-52.321-52.315V89.693c0-28.851,23.47-52.321,52.321-52.321s52.321,23.47,52.315,52.321V266.583z" /><rect x="237.314" y="409.226" width="37.372" height="84.088" /><path d="M305.207,474.628h-98.414c-10.321,0-18.686,8.365-18.686,18.686c0,10.321,8.365,18.686,18.686,18.686h98.414c10.321,0,18.686-8.365,18.686-18.686C323.893,482.993,315.528,474.628,305.207,474.628z" /><path d="M397.392,234.822c-10.321,0-18.686,8.365-18.686,18.686v14.949c0,67.656-55.049,122.706-122.706,122.706c-67.662,0-122.706-55.049-122.706-122.706v-14.949c0-10.321-8.365-18.686-18.686-18.686s-18.686,8.365-18.686,18.686v14.949c0,88.267,71.811,160.078,160.078,160.078s160.078-71.811,160.078-160.078v-14.949C416.078,243.188,407.713,234.822,397.32,234.822z" /></svg>
                  }
                </div>
              </div>
            </div>
            <div>
              <p className="label top">{logState}</p>
            </div>
          </div>
          <div style={{}}>
            {recentTweet ? <p className='label'>You tweeted: {recentTweet}</p> : null}</div>
        </div>

        <div style={{ width: '100%', paddingTop: '5%' }}>
          <div className='hcenter' style={{ width: '30%' }}>
            <Feature />
          </div>
          {/* <Waitlist api_key="AU57D6" /> */}

        </div>
        {/* <div className="ml-embedded" data-form="lfhdBA"></div> */}
        <div>
          <p className='label'><a target={"_blank"} href="https://www.buymeacoffee.com/nimishpatelme"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className='bmcimage' /></a></p>
          <p className='label'>Made with ❤️ by <a target={"_blank"} href='https://nimishpatel.me' style={{ color: '#acecb9' }}>Nimish Patel</a></p>
        </div>
      </main>

    </div>
  );
}

export default App;
